export interface App {
  appId: number;
  name: string;
  status: string;
  version: number;
  support: string;
  theme: string;
  iconAppField: string;
  androidReady: boolean;
  iPhoneReady: boolean;
  fullBundle: string;
  androidFullBundle: string;
  logo: string;
  iosCert: null | string;
  androidApiKey?: AndroidKey;
  iosKey?: IosKey;
  creationDate: string;
  planId: number;
  androidNotificationIsAvailable: boolean;
  serviceId: number;
}

export interface AppApi {
  idapplications: number;
  nom: string;
  status: string;
  version: number;
  support: string;
  themeGeneral: string;
  'icon-app-field': string;
  android_ready: boolean;
  iphone_ready: boolean;
  full_bundle: string;
  android_full_bundle: string;
  androidNotificationAvailable: boolean;
  logo: string;
  ios_cert: null | string;
  android_apikey?: AndroidKeyApi;
  ios_key?: IosKeyApi;
  dateCreation: string;
  idplan: number;
  serviceid: number;
}

export interface AdminApp extends App {
  bundleId: string;
  bundleIdAndroid: string;
  dateCreated: Date;
  userId: number;
  generationStatus: GenerationStatus;
  serviceId: number;
}


export interface AdminAppApi extends AppApi {
  bundleId: string;
  bundleId_android: string;
  dateCreation: string;
  idutilisateurs: number;
  generation_status: GenerationStatusApi;
  serviceid: number;
}

export interface IosKey {
  appId: number;
  filename: string;
  originalFilename: string;
  keyId: string;
  teamId: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface AndroidKey {
  appId: number;
  apiKey: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface GenerationStatus {
  appId: number;
  iosStatus: string;
  androidStatus: string;
  createdAt: Date;
  updatedAt: Date;
  androidMaj: number;
  iosMaj: number;
}


interface IosKeyApi {
  idapplication: number;
  filename: string;
  original_filename: string;
  key_id: string;
  team_id: string;
  created_at: string;
  updated_at: string;
}

interface AndroidKeyApi {
  idapplication: number;
  api_key: string;
  created_at: string;
  updated_at: string;
}

interface GenerationStatusApi {
  idapplication: number;
  ios_status: string;
  android_status: string;
  created_at: string;
  updated_at: string;
  android_maj: number;
  ios_maj: number;
}

export function transformApp(apiApp: AppApi): App {
  return {
    appId: apiApp.idapplications,
    name: apiApp.nom,
    status: apiApp.status,
    version: apiApp.version,
    support: apiApp.support,
    theme: apiApp.themeGeneral,
    iconAppField: apiApp['icon-app-field'],
    androidReady: apiApp.android_ready,
    iPhoneReady: apiApp.iphone_ready,
    fullBundle: apiApp.full_bundle,
    androidFullBundle: apiApp.android_full_bundle,
    logo: apiApp.logo,
    iosCert: apiApp.ios_cert,
    androidApiKey: apiApp.android_apikey ? transformAndroidKey(apiApp.android_apikey) : undefined,
    iosKey: apiApp.ios_key ? transformIosKey(apiApp.ios_key) : undefined,
    creationDate: apiApp.dateCreation,
    planId: apiApp.idplan,
    androidNotificationIsAvailable: apiApp.androidNotificationAvailable,
    serviceId: apiApp.serviceid,
  };
}

export function transformAdminApp(apiData: AdminAppApi): AdminApp {
  return {
    ...transformApp(apiData),
    bundleId: apiData.bundleId,
    bundleIdAndroid: apiData.bundleId_android,
    dateCreated: new Date(apiData.dateCreation),
    userId: apiData.idutilisateurs,
    generationStatus: apiData.generation_status ? transformGenerationStatus(apiData.generation_status) : null,
    serviceId: apiData.serviceid
  };
}

function transformIosKey(apiData: IosKeyApi): IosKey {
  return {
    appId: apiData.idapplication,
    filename: apiData.filename,
    originalFilename: apiData.original_filename,
    keyId: apiData.key_id,
    teamId: apiData.team_id,
    createdAt: new Date(apiData.created_at),
    updatedAt: new Date(apiData.updated_at),
  };
}

function transformAndroidKey(apiData: AndroidKeyApi): AndroidKey {
  return {
    appId: apiData.idapplication,
    apiKey: apiData.api_key,
    createdAt: new Date(apiData.created_at),
    updatedAt: new Date(apiData.updated_at),
  };
}

function transformGenerationStatus(apiStatus: GenerationStatusApi): GenerationStatus {
  return {
    appId: apiStatus.idapplication,
    iosStatus: apiStatus.ios_status,
    androidStatus: apiStatus.android_status,
    createdAt: new Date(apiStatus.created_at),
    updatedAt: new Date(apiStatus.updated_at),
    androidMaj: apiStatus.android_maj,
    iosMaj: apiStatus.ios_maj,
  };
}
