import { Env } from './environment.model';
export const environment: Env = {
  production: true,
  disableLogs: false,
  auth: {
    isLocalLoginEnabled: true,
    // TODO redirect URL
    redirectUrl:
      'https://www.dev.radioking.xyz/login?redirect=https:%2F%2Fmanager.dev.radioking.xyz',
  },
  urls: {
    MAIN_API: 'https://api.dev.radioking.xyz',
    MOBILE_API: 'https://www.dev.radioking.xyz/mobile/api',
    RADIOKING_DOMAIN: 'dev.radioking.xyz',
    SIMULATOR: 'https://mobile-simulator.dev.radioking.xyz',
  },
  whmcs: {
    url: 'https://www.dev.radioking.xyz/on',
    basic: {
      customFieldId: '173',
      planId: '59',
      publicationAddonId: '17',
    },
    smart: {
      customFieldId: '179',
      planId: '61',
      publicationAddonId: '19',
    },
    expert: {
      customFieldId: '183',
      planId: '63',
      publicationAddonId: '19',
    },
  },
  googleMapsKey: 'AIzaSyA1BNLwxFxrbnVeH5Er5387UFI0G2xeJjQ',
  gtm: 'GTM-TVKWT72',
  amplitudeKey: '02221b0d901a074475ad262a2d5536ca',
  cookies: {
    config: {
      domain: '.dev.radioking.xyz',
      expires: 365,
      secure: true,
      path: '/',
    },
    refreshTokenKey: 'refresh_token',
    tokenKey: 'token',
  },
};
