import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthState } from '@app/core/auth/states/auth.state';
import {
  LogEvent,
  LogUserProperty,
  SetEventProperties,
} from '@app/core/states/event-tracking.actions';
import { newStreamConfig } from '@app/features/app-creation-and-configuration/components/app-configuration/player-configuration/add-new-stream/newStreamConfig';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, of, tap } from 'rxjs';
import {
  AppConfigApi,
  AppConfigTabs,
  ImagesModel,
  StreamApi,
} from '../models/app-config.model';
import { RadioTabModel, TextAlign } from '../models/app-tabs.model';
import { AppConfigurationService } from '../services/app-configuration.service';
import {
  AddConfigAsset,
  AddNewStream,
  AddNewTab,
  CheckAppNameAvailabilityError,
  CheckAppNameAvailabilityRequest,
  CheckAppNameAvailabilitySuccess,
  DeleteStream,
  DeleteTab,
  FetchAppConfigError,
  FetchAppConfigRequest,
  FetchAppConfigSuccess,
  FetchAppNameAndLogoError,
  FetchAppNameAndLogoRequest,
  FetchAppNameAndLogoSuccess,
  FetchMountPointError,
  FetchMountPointsRequest,
  FetchMountPointsSuccess,
  FetchUserRadiosError,
  FetchUserRadiosRequest,
  FetchUserRadiosSuccess,
  PostAppConfigError,
  PostAppConfigRequest,
  PostAppConfigSuccess,
  PostAssetsError,
  PostAssetsRequest,
  PostAssetsSuccess,
  PostNameAndLogo,
  PostNameAndLogoError,
  PostNameAndLogoSuccess,
  RemoveImage,
  ResetApiErrorMessage,
  SetBackgroundType,
  SetPageNameError,
  TogglePayingPublicationAddon,
  UpdateActivePage,
  UpdateActiveSettingsCategory,
  UpdateAppConfig,
  UpdatePlanError,
  UpdatePlanRequest,
  UpdatePlanSuccess,
} from './app-configuration.actions';
import { NextStep } from './stepper.actions';
import { StepperState } from './stepper.state';

interface PlanName {
  id: number;
  name: string;
}

export const planNames: PlanName[] = [
  {
    id: 1,
    name: 'Start',
  },
  {
    id: 2,
    name: 'Pro',
  },
  {
    id: 3,
    name: 'Business',
  },
  {
    id: 4,
    name: 'Basic',
  },
  {
    id: 5,
    name: 'Smart',
  },
  {
    id: 6,
    name: 'Premium',
  },
];

export interface AppConfigAssets {
  [key: string]: File | string;
}

export interface UserRadio {
  idradio: number;
  name: string;
  slug: string;
}

export class AppConfigurationStateModel {
  activePageIndex: number;
  activeSettingsCategory: string;
  currentAppConfig: AppConfigApi;
  originalAppConfig: AppConfigApi;
  appConfigAssets: AppConfigAssets;
  isLoading: boolean;
  isLoadingAssets: boolean;
  typedAppNameIsAvailable: boolean;
  backgroundType: string;
  billing: string;
  userRadios: UserRadio[];
  icecastMountPoints: string[];
  pageNameError: boolean;
  apiErrorMessage: string;
  publicationAddOn: boolean;
}

@State<AppConfigurationStateModel>({
  name: 'appConfiguration',
  defaults: {
    activePageIndex: null,
    activeSettingsCategory: 'general',
    currentAppConfig: null,
    originalAppConfig: null,
    appConfigAssets: null,
    isLoading: false,
    isLoadingAssets: false,
    typedAppNameIsAvailable: true,
    backgroundType: 'color',
    userRadios: null,
    icecastMountPoints: null,
    pageNameError: false,
    billing: 'monthly',
    apiErrorMessage: null,
    publicationAddOn: false,
  },
})
@Injectable()
export class AppConfigurationState {
  constructor(
    private readonly appConfigurationService: AppConfigurationService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly translate: TranslateService,
  ) {}

  @Selector()
  static currentAppConfig(state: AppConfigurationStateModel): AppConfigApi {
    return state.currentAppConfig;
  }

  @Selector()
  static isLoading(state: AppConfigurationStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  static isLoadingAssets(state: AppConfigurationStateModel): boolean {
    return state.isLoadingAssets;
  }

  @Selector()
  static currentAppConfigId(state: AppConfigurationStateModel): number {
    return state.currentAppConfig.idapplications;
  }

  @Selector()
  static currentAppConfigName(state: AppConfigurationStateModel): string {
    return state.currentAppConfig.nom;
  }

  @Selector()
  static currentAppWhmcsUserId(state: AppConfigurationStateModel): number {
    // this ID is different from user ID
    // whmcs user ID is used to fetch the user radios
    return state.currentAppConfig.idutilisateurs;
  }

  @Selector()
  static currentAppConfigLanguage(state: AppConfigurationStateModel): string {
    return state.currentAppConfig.lang;
  }

  @Selector()
  static currentAppConfigPlayerTab(state: AppConfigurationStateModel): RadioTabModel {
    return state.currentAppConfig.onglets[0];
  }

  @Selector()
  static currentAppConfigHeaderTextColor(state: AppConfigurationStateModel): string {
    return state.currentAppConfig.onglets[0].nomColor;
  }

  @Selector()
  static currentAppConfigBackgroundColor(state: AppConfigurationStateModel): string {
    return state.currentAppConfig.onglets[0].backgroundColor;
  }

  @Selector()
  static currentAppConfigBackgroundColorSecondary(
    state: AppConfigurationStateModel,
  ): string {
    return state.currentAppConfig.onglets[0].backgroundColorSecondary;
  }

  @Selector()
  static currentAppConfigBackgroundColorStyle(state: AppConfigurationStateModel): string {
    return state.currentAppConfig.onglets[0].backgroundType;
  }

  @Selector()
  static currentAppConfigBackgroundGradientDirection(
    state: AppConfigurationStateModel,
  ): string {
    return state.currentAppConfig.onglets[0].gradientDirection;
  }

  @Selector()
  static currentAppConfigMenuLogo(state: AppConfigurationStateModel): string | null {
    const backgroundImage = state.currentAppConfig.images.find(
      image => image.key === 'logoappfield',
    );
    if (backgroundImage) {
      return backgroundImage.url;
    } else {
      return null;
    }
  }

  @Selector()
  static currentAppConfigMenuTextColor(state: AppConfigurationStateModel): string {
    return state.currentAppConfig.menuColor;
  }

  @Selector()
  static currentAppConfigMenuBackgroundColor(state: AppConfigurationStateModel): string {
    return state.currentAppConfig.menuBackgroundColor;
  }

  @Selector()
  static currentAppConfigShowBlurredCover(state: AppConfigurationStateModel): boolean {
    return state.currentAppConfig.onglets[0].showBlurredCover === 'YES' ? true : false;
  }

  @Selector()
  static currentAppConfigBackgroundImage(
    state: AppConfigurationStateModel,
  ): string | null {
    const backgroundImage = state.currentAppConfig.images.find(
      image => image.key === 'backgroundappfield',
    );

    if (backgroundImage) {
      return backgroundImage.url;
    } else {
      return null;
    }
  }

  @Selector()
  static currentAppConfigBackgroundImageLong(
    state: AppConfigurationStateModel,
  ): string | null {
    const backgroundImage = state.currentAppConfig.images.find(
      image => image.key === 'backgroundiphoneXappfield',
    );

    if (backgroundImage) {
      return backgroundImage.url;
    } else {
      return null;
    }
  }

  @Selector()
  static currentAppConfigDefaultCover(state: AppConfigurationStateModel): string | null {
    const coverImage = state.currentAppConfig.images.find(
      image => image.key === 'pochettedefaut',
    );

    if (coverImage) {
      return coverImage.url;
    } else {
      return null;
    }
  }

  @Selector()
  static currentAppConfigLogo(state: AppConfigurationStateModel): string {
    return (
      state.currentAppConfig.logo &&
      `https://${environment.urls.RADIOKING_DOMAIN}/upload/applications/${state.currentAppConfig.logo}`
    );
  }

  @Selector()
  static currentAppConfigPageList(state: AppConfigurationStateModel): AppConfigTabs {
    return state.currentAppConfig.onglets;
  }

  @Selector()
  static appConfigIsLoaded(state: AppConfigurationStateModel): boolean {
    return state.currentAppConfig !== null;
  }

  @Selector()
  static typedAppNameIsAvailable(state: AppConfigurationStateModel): boolean {
    return state.typedAppNameIsAvailable;
  }

  @Selector()
  static activeSettingsCategory(state: AppConfigurationStateModel): string {
    return state.activeSettingsCategory;
  }

  @Selector()
  static activePageIndex(state: AppConfigurationStateModel): number {
    return state.activePageIndex;
  }

  @Selector()
  static activePageType(state: AppConfigurationStateModel): string {
    return state.currentAppConfig.onglets[state.activePageIndex].type;
  }

  @Selector()
  static backgroundType(state: AppConfigurationStateModel): string {
    return state.backgroundType;
  }

  @Selector()
  static fontFamily(state: AppConfigurationStateModel): string {
    return state.currentAppConfig.onglets[0].fontFamily;
  }

  @Selector()
  static fontSize(state: AppConfigurationStateModel): number {
    return state.currentAppConfig.onglets[0].fontSize;
  }

  @Selector()
  static mainColor(state: AppConfigurationStateModel): string {
    return state.currentAppConfig.onglets[0].mainColor;
  }

  @Selector()
  static iconStyle(state: AppConfigurationStateModel): string {
    return state.currentAppConfig.onglets[0].iconStyle;
  }

  @Selector()
  static divRadius(state: AppConfigurationStateModel): number {
    return state.currentAppConfig.onglets[0].divRadius;
  }

  @Selector()
  static divShadow(state: AppConfigurationStateModel): number {
    return state.currentAppConfig.onglets[0].divShadow;
  }

  @Selector()
  static textAlign(state: AppConfigurationStateModel): TextAlign {
    return state.currentAppConfig.onglets[0].textAlign;
  }

  @Selector()
  static buttonStyle(state: AppConfigurationStateModel): string {
    return state.currentAppConfig.onglets[0].buttonStyle;
  }

  @Selector()
  static showMargins(state: AppConfigurationStateModel): boolean {
    return state.currentAppConfig.onglets[0].divWithMargin === 'YES' ? true : false;
  }

  @Selector()
  static billing(state: AppConfigurationStateModel): string {
    return state.billing;
  }

  @Selector()
  static showImageLoadingModal(state: AppConfigurationStateModel): boolean {
    return (
      state.backgroundType === 'image' &&
      state.currentAppConfig.onglets[0].backgroundappfield === null &&
      state.currentAppConfig.onglets[0].backgroundiphoneXappfield === null &&
      state.activeSettingsCategory === 'general'
    );
  }

  @Selector()
  static appBackgroundIsBlurred(state: AppConfigurationStateModel): boolean {
    return state.currentAppConfig.onglets[0].blurredBackground === 'YES' ? true : false;
  }

  @Selector()
  static currentAppConfigStreamList(state: AppConfigurationStateModel): StreamApi[] {
    return state.currentAppConfig.streams;
  }

  @Selector()
  static userRadios(state: AppConfigurationStateModel): UserRadio[] {
    return state.userRadios;
  }

  @Selector()
  static icecastMountPoints(state: AppConfigurationStateModel): string[] {
    return state.icecastMountPoints;
  }

  @Selector()
  static pageNameError(state: AppConfigurationStateModel): boolean {
    return state.pageNameError;
  }

  @Selector()
  static currentAppIdPlan(state: AppConfigurationStateModel): number {
    return state.currentAppConfig.newidplan;
  }

  @Selector()
  static monetizationAvailable(state: AppConfigurationStateModel): boolean {
    return state.currentAppConfig.monetizationAvailable;
  }

  @Selector()
  static availableOnAndroidTV(state: AppConfigurationStateModel): boolean {
    return state.currentAppConfig.availableOnAndroidTV;
  }

  @Selector()
  static availableOnAppleTV(state: AppConfigurationStateModel): boolean {
    return state.currentAppConfig.availableOnAppleTV;
  }

  @Selector()
  static apiErrorMessage(state: AppConfigurationStateModel): string {
    return state.apiErrorMessage;
  }

  @Selector()
  static isConfigUpdated(state: AppConfigurationStateModel): boolean {
    return (
      JSON.stringify(state.currentAppConfig) !==
        JSON.stringify(state.originalAppConfig) || state.appConfigAssets !== null
    );
  }

  @Selector()
  static appConfigAssetList(state: AppConfigurationStateModel): AppConfigAssets {
    return state.appConfigAssets;
  }

  // fetch app name and logo

  @Action(FetchAppNameAndLogoRequest)
  fetchAppNameAndLogoRequest(
    ctx: StateContext<AppConfigurationStateModel>,
    { appId }: FetchAppNameAndLogoRequest,
  ) {
    ctx.patchState({
      isLoading: true,
    });

    return this.appConfigurationService.getNameAndLogo(appId).pipe(
      tap(apiResponse => {
        ctx.dispatch(new FetchAppNameAndLogoSuccess(apiResponse));
      }),
      catchError(error => ctx.dispatch(new FetchAppNameAndLogoError(error))),
    );
  }

  @Action(FetchAppNameAndLogoSuccess)
  fetchAppNameAndLogoSuccess(
    ctx: StateContext<AppConfigurationStateModel>,
    { apiResponse }: FetchAppNameAndLogoSuccess,
  ) {
    ctx.patchState({
      currentAppConfig: {
        ...ctx.getState().currentAppConfig,
        nom: apiResponse.nom,
        logo: apiResponse.logo,
      },
      isLoading: false,
    });
  }

  @Action(FetchAppNameAndLogoError)
  fetchAppNameAndLogoError() {
    const userRKDomain = this.store.selectSnapshot(AuthState.userRKDomain);
    window.location.href = `${userRKDomain}/on/myapps.php`;
  }

  // post app name and logo

  @Action(PostNameAndLogo)
  postNameAndLogo(
    ctx: StateContext<AppConfigurationStateModel>,
    { name, logo, appId }: PostNameAndLogo,
  ) {
    ctx.patchState({
      isLoading: true,
    });

    return this.appConfigurationService.postNameAndLogo(name, logo, appId).pipe(
      tap(apiResponse => {
        ctx.dispatch(new PostNameAndLogoSuccess(apiResponse));
      }),
      catchError(error => {
        // event tracking
        this.store.dispatch(
          new SetEventProperties({
            name: 'ErrorSaveNameAndLogo',
            error_type: error.status,
          }),
        );
        this.store.dispatch(new LogEvent('Server Error'));

        return ctx.dispatch(new PostNameAndLogoError(error));
      }),
    );
  }

  @Action(PostNameAndLogoSuccess)
  postNameAndLogoSuccess(
    ctx: StateContext<AppConfigurationStateModel>,
    { currentAppConfig }: FetchAppConfigSuccess,
  ) {
    ctx.patchState({
      currentAppConfig,
      originalAppConfig: JSON.parse(JSON.stringify(currentAppConfig)), // deep copy to prevent reference issues
      isLoading: false,
    });
    this.store.dispatch(new NextStep());
  }

  @Action(PostNameAndLogoError)
  postNameAndLogoError(ctx: StateContext<AppConfigurationStateModel>) {
    ctx.patchState({
      isLoading: false,
    });
  }

  // fetch app config

  @Action(FetchAppConfigRequest)
  fetchAppConfigRequest(
    ctx: StateContext<AppConfigurationStateModel>,
    { appId }: FetchAppConfigRequest,
  ) {
    ctx.patchState({
      isLoading: true,
    });

    return this.appConfigurationService.getAppConfig(appId).pipe(
      tap(apiResponse => {
        ctx.dispatch(new FetchAppConfigSuccess(apiResponse));
      }),
      catchError(() => ctx.dispatch(new FetchAppConfigError())),
    );
  }

  @Action(FetchAppConfigSuccess)
  fetchAppConfigSuccess(
    ctx: StateContext<AppConfigurationStateModel>,
    { currentAppConfig }: FetchAppConfigSuccess,
  ) {
    ctx.patchState({
      currentAppConfig,
      originalAppConfig: JSON.parse(JSON.stringify(currentAppConfig)),
    });

    // track plan
    const currentAppPlan = planNames.find(plan => plan.id === currentAppConfig.newidplan);
    this.store.dispatch(new LogUserProperty('plan_name', currentAppPlan.name));
  }

  @Action([FetchAppConfigSuccess, FetchAppConfigError])
  toggleLoadingState(ctx: StateContext<AppConfigurationStateModel>) {
    ctx.patchState({
      isLoading: false,
    });
  }

  @Action(FetchAppConfigError)
  FetchAppsError() {
    const userRKDomain = this.store.selectSnapshot(AuthState.userRKDomain);
    window.location.href = `${userRKDomain}/on/myapps.php`;
  }

  // post app config

  @Action(PostAppConfigRequest)
  postAppConfigRequest(ctx: StateContext<AppConfigurationStateModel>) {
    const config = ctx.getState().currentAppConfig;
    const configAssets = ctx.getState().appConfigAssets;
    ctx.patchState({
      isLoading: true,
    });

    return this.appConfigurationService
      .postAppConfig(config, config.idapplications, configAssets)
      .pipe(
        tap(appConfig => {
          ctx.dispatch(new PostAppConfigSuccess(appConfig));
        }),
        catchError(error => {
          if (error.status === 400) {
            ctx.dispatch(new PostAppConfigError(error.error.error));
          } else {
            ctx.dispatch(
              new PostAppConfigError(this.translate.instant('toast.generic-error')),
            );
          }

          // event tracking
          this.store.dispatch(
            new SetEventProperties({
              name: 'ErrorSaveConfig',
              error_type: error.status,
              error_content: error.error.error,
            }),
          );
          this.store.dispatch(new LogEvent('Server Error'));

          return of();
        }),
      );
  }

  @Action(PostAppConfigSuccess)
  postAppConfigSuccess(
    ctx: StateContext<AppConfigurationStateModel>,
    { appConfig }: PostAppConfigSuccess,
  ) {
    ctx.patchState({
      appConfigAssets: null,
      currentAppConfig: appConfig,
      apiErrorMessage: null,
      isLoading: false,
    });
    if (this.store.selectSnapshot(StepperState.showSteps)) {
      if (appConfig.paye === 'YES') {
        this.store.dispatch(new NextStep());
      } else {
        this.router.navigateByUrl('/plan/' + appConfig.idapplications);
      }
    }
  }

  @Action(PostAppConfigError)
  postAppConfigError(
    ctx: StateContext<AppConfigurationStateModel>,
    { error }: PostAppConfigError,
  ) {
    ctx.patchState({
      apiErrorMessage: error,
      isLoading: false,
    });
  }

  // post assets

  @Action(PostAssetsRequest)
  postAssetsRequest(ctx: StateContext<AppConfigurationStateModel>) {
    const appId = ctx.getState().currentAppConfig.idapplications;
    const assets = ctx.getState().appConfigAssets;
    ctx.patchState({
      isLoadingAssets: true,
    });

    return this.appConfigurationService.postAssets(appId, assets).pipe(
      tap(() => {
        ctx.dispatch(new PostAssetsSuccess());
      }),
      catchError(error => {
        // event tracking
        this.store.dispatch(
          new SetEventProperties({
            name: 'ErrorSaveAssets',
            error_type: error.status,
            error_content: error.error.error,
          }),
        );
        this.store.dispatch(new LogEvent('Server Error'));

        return ctx.dispatch(new PostAssetsError(error.error.error));
      }),
    );
  }

  @Action(PostAssetsSuccess)
  postAssetsSuccess(ctx: StateContext<AppConfigurationStateModel>) {
    const app = ctx.getState().currentAppConfig;
    const billing = ctx.getState().billing.toLowerCase();
    ctx.patchState({
      apiErrorMessage: null,
    });
    if (app.paye === 'YES') {
      // redirects to publication page
      window.location.href = `${environment.urls.MOBILE_API}/publication/app/${app.idapplications}`;
    } else {
      // sets paying publication addon option
      const hasAddonOption = ctx.getState().publicationAddOn;
      const addonOptionIds: { [key: number]: string } = {
        4: environment.whmcs.basic.publicationAddonId,
        5: environment.whmcs.smart.publicationAddonId,
        6: environment.whmcs.expert.publicationAddonId,
      };
      const addonOption = hasAddonOption
        ? `&addons[${addonOptionIds[app.newidplan]}]`
        : '';
      // sets offer
      let offer = environment.whmcs.basic;
      if (app.newidplan === 5) {
        offer = environment.whmcs.smart;
      } else if (app.newidplan === 6) {
        offer = environment.whmcs.expert;
      }
      // redirects to whmcs shopping cart
      // eslint-disable-next-line max-len
      window.location.href = `${environment.whmcs.url}/cart.php?a=add&pid=${offer.planId}&customfield[${offer.customFieldId}]=${app.idapplications}&billingcycle=${billing}${addonOption}`;
    }
  }

  @Action(PostAssetsError)
  postAssetsError(
    ctx: StateContext<AppConfigurationStateModel>,
    { error }: PostAssetsError,
  ) {
    ctx.patchState({
      isLoadingAssets: false,
      apiErrorMessage: error,
    });
  }

  // check name availability

  @Action(CheckAppNameAvailabilityRequest)
  checkAppNameAvailability(
    ctx: StateContext<AppConfigurationStateModel>,
    { name }: CheckAppNameAvailabilityRequest,
  ) {
    return this.appConfigurationService.checkAppName(name).pipe(
      tap(availability => {
        ctx.dispatch(new CheckAppNameAvailabilitySuccess(availability));
      }),
      catchError(() => ctx.dispatch(new CheckAppNameAvailabilityError())),
    );
  }

  @Action(CheckAppNameAvailabilitySuccess)
  checkAppNameAvailabilitySuccess(
    ctx: StateContext<AppConfigurationStateModel>,
    { availability }: CheckAppNameAvailabilitySuccess,
  ) {
    ctx.patchState({
      typedAppNameIsAvailable: availability,
    });
  }

  // update app config

  @Action(UpdateAppConfig)
  updateAppConfig(
    ctx: StateContext<AppConfigurationStateModel>,
    { path, value }: UpdateAppConfig,
  ) {
    const state = ctx.getState();
    const updatedConfig = JSON.parse(JSON.stringify(state.currentAppConfig));
    updateNestedObject(updatedConfig, path, value);
    ctx.patchState({
      currentAppConfig: updatedConfig,
    });
  }

  // update active settings category

  @Action(UpdateActiveSettingsCategory)
  UpdateActiveSettingsCategory(
    ctx: StateContext<AppConfigurationStateModel>,
    { category }: UpdateActiveSettingsCategory,
  ) {
    ctx.patchState({
      activeSettingsCategory: category,
    });
  }

  // update active page

  @Action(UpdateActivePage)
  updateActivePage(
    ctx: StateContext<AppConfigurationStateModel>,
    { page }: UpdateActivePage,
  ) {
    ctx.patchState({
      activePageIndex: page,
    });
  }

  // add config asset

  @Action(AddConfigAsset)
  addConfigAsset(
    ctx: StateContext<AppConfigurationStateModel>,
    { name, value, imageUrl }: AddConfigAsset,
  ) {
    const state = ctx.getState();

    // 1.temporarily replaces the image in the state to display it in the React App
    // applies to the assets that need to be shown in the app in real time

    if (
      name === 'backgroundappfield' ||
      name === 'logoappfield' ||
      name === 'backgroundappfield' ||
      name === 'backgroundiphoneXappfield' ||
      name === 'pochettedefaut' ||
      /^logoflux\d+appfield$/.test(name)
    ) {
      // 1.a replaces the image in the image array
      let images = [...state.currentAppConfig.images];
      const foundIndex = images.findIndex(image => image.key === name);
      if (foundIndex !== -1) {
        images[foundIndex] = {
          key: name,
          nom: name,
          url: imageUrl,
        };
      } else {
        const newImage: ImagesModel = {
          key: name,
          nom: name,
          url: imageUrl,
        };
        images = [...images, newImage];
      }
      // 1.b updates the radio tab to display the new background image in the React App
      const newRadioTab = {
        ...state.currentAppConfig.onglets[0],
        [name]: name,
      };
      const updatedTabs = [newRadioTab, ...state.currentAppConfig.onglets.slice(1)];
      const updatedTabsAsAppConfigTabs = updatedTabs as AppConfigTabs;
      ctx.patchState({
        currentAppConfig: {
          ...state.currentAppConfig,
          images,
          onglets: updatedTabsAsAppConfigTabs,
        },
      });

      ctx.dispatch(new ResetApiErrorMessage());
    }

    // 2. adds the file to the appConfigAssets state

    const currentAssets = ctx.getState().appConfigAssets;
    const updatedAssets = { ...currentAssets, [name]: value };
    ctx.patchState({
      appConfigAssets: updatedAssets,
    });
  }

  // remove existing image

  @Action(RemoveImage)
  removeImage(ctx: StateContext<AppConfigurationStateModel>, { name }: RemoveImage) {
    const state = ctx.getState();
    const images = [...state.currentAppConfig.images];

    const foundIndex = images.findIndex(image => image.key === name);
    images[foundIndex] = {
      key: name,
      nom: null,
      url: null,
    };
    const newRadioTab: RadioTabModel = {
      ...state.currentAppConfig.onglets[0],
      [name]: null,
    };
    const updatedTabs = [newRadioTab, ...state.currentAppConfig.onglets.slice(1)];
    const updatedTabsAsAppConfigTabs = updatedTabs as AppConfigTabs;
    const updatedAssets = { ...state.appConfigAssets };
    delete updatedAssets[name];
    ctx.patchState({
      currentAppConfig: {
        ...state.currentAppConfig,
        images,
        onglets: updatedTabsAsAppConfigTabs,
      },
      appConfigAssets: updatedAssets,
    });

    ctx.dispatch(new ResetApiErrorMessage());
  }

  // add new tab

  @Action(AddNewTab)
  addNewTab(ctx: StateContext<AppConfigurationStateModel>, { newTab, index }: AddNewTab) {
    const state = ctx.getState();

    const newTabType = newTab.type;

    // counts the existing tabs of the same type as the new tab
    const numberOfTabsOfSameType = state.currentAppConfig.onglets.filter(
      tab => tab.type === newTabType,
    ).length;

    // checks if there are already 50 or more tabs of the same type
    if (numberOfTabsOfSameType >= 50) {
      console.error(
        `Cannot add more tabs of type "${newTabType}". Limit of 50 tabs of this type has been reached.`,
      );

      return;
    }

    // starts updating the list
    const updatedTabs = [...state.currentAppConfig.onglets];
    let currentTabIndex = index;

    if (index !== undefined && index > 0 && index <= updatedTabs.length) {
      updatedTabs.splice(index, 0, newTab); // insert at the specified index
    } else {
      updatedTabs.push(newTab); // add to the end if no index is specified
      currentTabIndex = updatedTabs.length - 1; // set active tab to be the last one in the list
    }

    const updatedTabsAsAppConfigTabs = updatedTabs as AppConfigTabs;

    ctx.patchState({
      currentAppConfig: {
        ...state.currentAppConfig,
        onglets: updatedTabsAsAppConfigTabs,
      },
      activeSettingsCategory: 'pages',
      activePageIndex: currentTabIndex,
    });

    ctx.dispatch(new ResetApiErrorMessage());
  }

  // delete tab

  @Action(DeleteTab)
  deleteTab(ctx: StateContext<AppConfigurationStateModel>, { index }: DeleteTab) {
    const state = ctx.getState();
    const updatedTabs = state.currentAppConfig.onglets.filter(
      (_, tabIndex) => tabIndex !== index,
    ) as AppConfigTabs;
    const updatedConfig = {
      ...state.currentAppConfig,
      onglets: updatedTabs,
    };
    ctx.patchState({
      currentAppConfig: updatedConfig,
      activePageIndex: 0,
    });

    ctx.dispatch(new ResetApiErrorMessage());
  }

  // set background type

  @Action(SetBackgroundType)
  setBackgroundType(
    ctx: StateContext<AppConfigurationStateModel>,
    { type }: SetBackgroundType,
  ) {
    ctx.patchState({
      backgroundType: type,
    });

    ctx.dispatch(new ResetApiErrorMessage());
  }

  // update plan

  @Action(UpdatePlanRequest)
  updatePlanRequest(
    ctx: StateContext<AppConfigurationStateModel>,
    { planId, billing }: UpdatePlanRequest,
  ) {
    const config = ctx.getState().currentAppConfig;

    ctx.patchState({
      isLoading: true,
      billing,
    });

    return this.appConfigurationService.plan(planId, config.idapplications).pipe(
      tap(() => {
        ctx.dispatch(new UpdatePlanSuccess());
      }),
      catchError(() => ctx.dispatch(new UpdatePlanError())),
    );
  }

  @Action(UpdatePlanSuccess)
  updatePlanSuccess(ctx: StateContext<AppConfigurationStateModel>) {
    const config = ctx.getState().currentAppConfig;

    ctx.patchState({
      isLoading: false,
    });
    this.router.navigateByUrl('/assets/' + config.idapplications);
  }

  // fetch user radios

  @Action(FetchUserRadiosRequest)
  fetchUserRadiosRequest(
    ctx: StateContext<AppConfigurationStateModel>,
    { userId }: FetchUserRadiosRequest,
  ) {
    return this.appConfigurationService.getUserRadios(userId).pipe(
      tap(apiResponse => {
        ctx.dispatch(new FetchUserRadiosSuccess(apiResponse));
      }),
      catchError(error => ctx.dispatch(new FetchUserRadiosError(error))),
    );
  }

  @Action(FetchUserRadiosSuccess)
  fetchUserRadiosSuccess(
    ctx: StateContext<AppConfigurationStateModel>,
    { apiResponse }: FetchUserRadiosSuccess,
  ) {
    ctx.patchState({
      userRadios: apiResponse,
    });
  }

  @Action(FetchUserRadiosError)
  fetchUserRadiosError() {}

  // fetch mount points

  @Action(FetchMountPointsRequest)
  fetchMountPointsRequest(
    ctx: StateContext<AppConfigurationStateModel>,
    { url }: FetchMountPointsRequest,
  ) {
    return this.appConfigurationService.getIcecastMountPoints(url).pipe(
      tap(apiResponse => {
        ctx.dispatch(new FetchMountPointsSuccess(apiResponse));
      }),
      catchError(error => ctx.dispatch(new FetchMountPointError(error))),
    );
  }

  @Action(FetchMountPointsSuccess)
  fetchMountPointsSuccess(
    ctx: StateContext<AppConfigurationStateModel>,
    { apiResponse }: FetchMountPointsSuccess,
  ) {
    ctx.patchState({
      icecastMountPoints: apiResponse,
    });
  }

  @Action(FetchMountPointError)
  fetchMountPointError() {}

  // add new stream

  @Action(AddNewStream)
  addNewStream(ctx: StateContext<AppConfigurationStateModel>) {
    const state = ctx.getState();
    const newStream = newStreamConfig;
    const updatedStreams = [...state.currentAppConfig.streams, newStream];

    ctx.patchState({
      currentAppConfig: {
        ...state.currentAppConfig,
        streams: updatedStreams,
      },
    });

    ctx.dispatch(new ResetApiErrorMessage());
  }

  @Action(DeleteStream)
  deleteStream(ctx: StateContext<AppConfigurationStateModel>, { index }: DeleteStream) {
    const state = ctx.getState();
    const updatedStreams = state.currentAppConfig.streams.filter(
      (_, streamIndex) => streamIndex !== index,
    );
    const updatedConfig = {
      ...state.currentAppConfig,
      streams: updatedStreams,
    };
    ctx.patchState({
      currentAppConfig: updatedConfig,
    });

    ctx.dispatch(new ResetApiErrorMessage());
  }

  @Action(SetPageNameError)
  setPageNameError(
    ctx: StateContext<AppConfigurationStateModel>,
    { error }: SetPageNameError,
  ) {
    ctx.patchState({
      pageNameError: error,
    });
  }

  @Action(ResetApiErrorMessage)
  resetApiErrorMessage(ctx: StateContext<AppConfigurationStateModel>) {
    ctx.patchState({
      apiErrorMessage: null,
    });
  }

  @Action(TogglePayingPublicationAddon)
  togglePayingPublicationAddon(
    ctx: StateContext<AppConfigurationStateModel>,
    { publicationAddOn }: TogglePayingPublicationAddon,
  ) {
    ctx.patchState({
      publicationAddOn,
    });
  }
}

function updateNestedObject(obj: Record<string, any>, path: string[], value: any): void {
  if (path.length === 1) {
    obj[path[0]] = value;
  } else {
    if (obj[path[0]] === undefined || obj[path[0]] === null) {
      obj[path[0]] = {};
    }
    updateNestedObject(obj[path[0]], path.slice(1), value);
  }
}
